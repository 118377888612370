import React from 'react';
import { useState, useEffect } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonWallet } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useLocation } from 'react-router-dom';

import './Header.css';

import logo from './Images/logo.svg';
import logodark from './Images/logo-dark.svg';
import logomobile from './Images/logo-mobile.svg';
import down from './Images/down.svg';
import walleti from './Images/wallet.svg';
import copy from './Images/copy.svg';
import luna from './Images/luna.svg';
import solnce from './Images/solnce.svg';
import exit from './Images/exit.svg';

import defaultblue from './Images/default-blue.svg';
import orderblack from './Images/order-black.svg';

const Header: React.FC = () => {
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const wallet = useTonWallet();
    const userFriendlyAddress = useTonAddress();
    const location = useLocation();

    const [theme, setTheme] = useState<'light' | 'dark'>('light');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(savedTheme as 'light' | 'dark');
            document.body.className = savedTheme;
        }
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        document.body.className = newTheme;
    };

    const formatAddress = (address: string) => {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    const navigateTo = (path: string) => {
        window.location.href=`${path}`;
    };

    return (
        <div className="header">
            <div className="header-container">
                <div className="container-start">
                    <div className="container-logo pc">
                    <img src={theme === 'dark' ? logodark : logo} alt="" onClick={() => navigateTo('/')} />
                    </div>
                    <div className="container-menu">
										<div
                        className={`container-menu-item dropdown ${location.pathname === '/' ? 'active' : ''}`}
                        onClick={() => navigateTo('/')}
                        >
                            Трейдинг <img src={down} alt="" />
                            <div className="dropdown-content">
                                <div className="dropdown-content-item actives">
                                    <img src={defaultblue} alt="" />
                                    <div className="dropdown-content-list">
                                        <div className="title">Простой режим</div>
                                        <div className="desc">Самый удобный способ трейдинга</div>
                                    </div>
                                </div>
                                <div className="dropdown-content-item" onClick={() => navigateTo('/')}>
                                    <img src={orderblack} alt="" />
                                    <div className="dropdown-content-list">
                                        <div className="title daa">Лимитный ордер</div>
                                        <div className="desc daa">Запланируйте обмен по более выгодной цене</div>
                                    </div>
                                </div>
                            </div>
                        </div>
												<div
    className={`container-menu-item ${location.pathname === '/pools' || location.pathname === '/add-liquidity' ? 'active' : ''}`}
    onClick={() => navigateTo('/pools')}
                        >
                            Пуллы
                        </div>
                        <div className="container-menu-item">
                            <div className="item-name">Stake</div>
                            <div className="soon"><span>СКОРО</span></div>
                        </div>
                        <div className="container-menu-item"><div className="item-name">P2P</div>
                        <div className="soon"><span>СКОРО</span></div></div>
                        <div className="container-menu-item"><div className="item-name">Ребалансировка</div>
                        <div className="soon"><span>СКОРО</span></div></div>
                    </div>
                </div>
                <div className="container-end">
                    <div className="container-other">
                        <div className="container-other-wallet" onClick={() => tonConnectUI.openModal()} >
                            <img src={walleti} alt="" />
                            <span>{wallet ? formatAddress(userFriendlyAddress) + ' ' : 'Подключить кошелёк'}</span>
                            {wallet && <img src={copy} alt="" />}
                        </div>
                        <div className="container-other-wallet-a">
                            <img src={walleti} alt="" />
                        </div>
                        <div className="container-other-select">
                        {wallet && (
                            <div className="container-other-select__exit" onClick={() => {
                                tonConnectUI.disconnect() 
                            }}>
                                <img src={exit} alt="" />
                            </div>
                                )}
                        <div className="container-other-select__theme" onClick={toggleTheme}>
                        <img src={theme === 'dark' ? solnce : luna} alt=""/>
                            </div>
    <div className="container-other-select__language">
        RU
    </div>
</div>
                    </div>
                </div>
            </div>
            <div className="header-a">
                <div className="container-other-wallet-a">
                    <img src={walleti} alt="" />
                </div>
            </div>
            <div className="header-container-a">
                <div className="container-start">
                    <div className="container-logo">
                        <img src={logomobile} alt="" />
                        <img src={walleti} alt="" onClick={() => tonConnectUI.openModal()} />
                    </div>
                    <div className="container-menu">
                    <div
                        className={`container-menu-item dropdown ${location.pathname === '/' ? 'active' : ''}`}
                        onClick={() => navigateTo('/')}
                        >
                            Трейдинг <img src={down} alt="" />
                            {/* <div className="dropdown-content">
                                <div className="dropdown-content-item actives">
                                    <img src={defaultblue} alt="" />
                                    <div className="dropdown-content-list">
                                        <div className="title">Простой режим</div>
                                        <div className="desc">Самый удобный способ трейдинга</div>
                                    </div>
                                </div>
                                <div className="dropdown-content-item">
                                    <img src={orderblack} alt="" />
                                    <div className="dropdown-content-list">
                                        <div className="title">Лимитный ордер</div>
                                        <div className="desc">Запланируйте обмен по более выгодной цене</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div
                        className={`container-menu-item dropdown ${location.pathname === '/pools' ? 'active' : ''}`}
                        onClick={() => navigateTo('/pools')}
                        >
                            Пуллы
                        </div>
                        <div className="container-menu-item"><div className="container-menu-item">
                            <div className="item-name">Stake</div>
                            <div className="soon"><span>СКОРО</span></div>
                        </div></div>
                    </div>
                </div>
                <div className="container-end">
                </div>
            </div>
            <div className="header-a">
                <div className="container-other-wallet-a">
                    <img src={walleti} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Header;