import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './Pools.css';

import video from './Images/video.svg';
import videodark from './Images/video-dark.svg';
import ton from './Images/ton.svg';
import coin from './Images/coin.svg';
import downselect from './Images/downselect.svg';
import arrows from './Images/arrows.svg';
import arrowslight from './Images/arrowslight.svg';
import filterdark from './Images/filter-dark.svg';
import filterlight from './Images/filter-light.svg';

import testToken from './Images/tokenimg.svg';

const Pools: React.FC = () => {
    const [activeTab, setActiveTab] = useState('all');
    const [expandedItem, setExpandedItem] = useState<number | null>(null);
    const [theme, setTheme] = useState<'light' | 'dark'>('light');

    const liquidityData = [
        {
            pair: "BTC/USDT",
            myTotalLiquidity: "$4 534",
            farmAPR: "84 %",
            token: {
                image: testToken,
                name: "REBA"
            },
            rewardsInDay: "$1.15/$9.32",
            totalForClaim: "$190.58",
            details: {
                providedREBA: "1 016 023.5281",
                providedUSDT: "2 204.2443",
                lpTokens: "1.4771",
                totalReward: "995 000 REBA",
                endIn: "995 000 REBA",
            }
        },
        {
            pair: "ETH/USDT",
            myTotalLiquidity: "$3 200",
            farmAPR: "75 %",
            token: {
                image: testToken,
                name: "ETHA"
            },
            rewardsInDay: "$0.85/$8.00",
            totalForClaim: "$100.00",
            details: {
                providedREBA: "500 321.1234",
                providedUSDT: "1 500.1234",
                lpTokens: "0.9678",
                totalReward: "500 000 ETHA",
                endIn: "500 000 ETHA",
            }
        }
    ];

    const [isRotated, setIsRotated] = useState<boolean[]>(Array(liquidityData.length).fill(false));

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(savedTheme as 'light' | 'dark');
            document.body.className = savedTheme;
        }
    }, []);

    const toggleInfo = (index: number) => {
        if (activeTab === 'my') {
            setExpandedItem(expandedItem === index ? null : index);
            setIsRotated((prev) => prev.map((rotated, i) => (i === index ? !rotated : rotated)));
        }
    };

    const navigateTo = (path: string) => {
        window.location.href=`${path}`;
    };

    const renderTable = () => {
        if (activeTab === 'all') {
            return (
                <div className="liquidity__table">
                    <div className="liquidity__table-item">
                        <div className="liquidity__table-item__value">
                            <div className="liquidity__table-item__pair">
                                <div className="liquidity__table-item__pair-images">
                                    <div className="one-image">
                                        <img src={ton} alt="" />
                                        <div className="two-image">
                                            <img src={coin} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__pair-name">BTC/USDT</div>
                            </div>
                        </div>
                        <div className="liquidity__table-item__value">
                            <div className="liquidity__table-item__tvl">
                                <div className="liquidity__table-item__tvl-title">TVL</div>
                                <div className="liquidity__table-item__tvl-value">$94 562 734</div>
                            </div>
                        </div>
                        <div className="liquidity__table-item__value">
                            <div className="liquidity__table-item__tvl">
                                <div className="liquidity__table-item__tvl-title">Volume 24h</div>
                                <div className="liquidity__table-item__tvl-value">$94 562 734</div>
                            </div>
                        </div>
                        <div className="liquidity__table-item__value">
                            <div className="liquidity__table-item__tvl">
                                <div className="liquidity__table-item__tvl-title">Добыча</div>
                                <div className="liquidity__table-item__tvl-value">USDS</div>
                            </div>
                        </div>
                        <div className="liquidity__table-item__value">
                            <div className="liquidity__table-item__tvl">
                                <div className="liquidity__table-item__tvl-title">24 h APR</div>
                                <div className="liquidity__table-item__tvl-value">1,1 %</div>
                            </div>
                        </div>
                        <div className="liquidity__table-item__value">
                            <div className="liquidity__table-item__value--button">Добавить</div>
                        </div>
                    </div>
                </div>
            );
        } else if (activeTab === 'my') {
            return (
                <div className="liquidity__table">
                    {liquidityData.map((item, index) => (
                        <div key={index} className="container-liquidity__table-item">
                            <div className="liquidity__table-item">
                                <div className="liquidity__table-item__value">
                                    <div className="liquidity__table-item__pair">
                                        <div className="liquidity__table-item__pair-images">
                                            <div className="one-image">
                                                <img src={ton} alt="" />
                                                <div className="two-image">
                                                    <img src={item.token.image} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="liquidity__table-item__pair-name">
                                            {item.pair}
                                            <div className="absolute"><span>Farm</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">My total liquidity</div>
                                        <div className="liquidity__table-item__tvl-value">{item.myTotalLiquidity}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Farm APR</div>
                                        <div className="liquidity__table-item__tvl-value">{item.farmAPR}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Token</div>
                                        <div className="liquidity__table-item__tvl-value">
                                            <div className="liquidity__table-item__tvl-value-img">
                                                <img src={item.token.image} alt="" />
                                            </div>
                                            <div className="liquidity__table-item__tvl-value-text">{item.token.name}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Rewards in Day</div>
                                        <div className="liquidity__table-item__tvl-value">{item.rewardsInDay}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Total For claim</div>
                                        <div className="liquidity__table-item__tvl-value green">{item.totalForClaim}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <img
                                        src={downselect}
                                        alt=""
                                        onClick={() => toggleInfo(index)}
                                        style={{ cursor: 'pointer', transform: isRotated[index] ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                                    />
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__value--button">Забрать</div>
                                </div>
                            </div>
            
                            <div className="liquidity__table-item-buttons">
                                <div
                                    className="liquidity__table-item-buttons_item inff"
                                    onClick={() => toggleInfo(index)}
                                    style={{ cursor: 'pointer' }}>
                                    Подробнее
                                </div>
                                <div className="liquidity__table-item-buttons_item gradient">
                                    Забрать
                                </div>
                            </div>
            
                            {expandedItem === index && (
                                <div className="liquidity__table-item-info">
                                    <div className="liquidity__details">
                                        <div className="liquidity__details-section">
                                            <h3>Ваша ликвидность</h3>
                                            <p>REBA provided: {item.details.providedREBA}</p>
                                            <p>USDT provided: {item.details.providedUSDT}</p>
                                            <p>LP tokens: {item.details.lpTokens}</p>
                                            <p>In Farm positions: {item.myTotalLiquidity}</p>
                                            <div className="line"></div>
                                            <div className="liquidity__details-actions">
                                                <button className="liquidity__button-withdraw">Withdraw</button>
                                                <button className="liquidity__button-deposit">Deposit</button>
                                            </div>
                                        </div>
                                        <div className="liquidity__details-section">
                                            <h3>Token rates</h3>
                                            <p>1 USDT = 4 654.4818 REBA ($3.81)</p>
                                            <p>1 REBA = 0.0021531 USDT ($0.01)</p>
                                            <p>TVL = $7 923</p>
                                        </div>
                                        <div className="liquidity__details-section">
                                            <h3>Pool reserves</h3>
                                            <p>REBA = 68 198 168.0267 ($15 380)</p>
                                            <p>USDT = 3 263.6723 ($3 181)</p>
                                            <p>Volume 24hr = $7 109</p>
                                        </div>
                                        <div className="liquidity__details-section">
                                            <h3>Информация о фарминге</h3>
                                            <p>Rewards day: 999.50 REBA / day</p>
                                            <p>Total for claim: {item.details.totalReward} ($3.60)</p>
                                            <div className="container-pb">
                                                <div className="container-pb__item left">
                                                    <div className="item__title">Total Rewards</div>
                                                    <div className="item__value">
                                                        {item.details.totalReward}
                                                    </div>
                                                </div>
                                                <div className="container-pb__item right">
                                                    <div className="item__title">End in</div>
                                                    <div className="item__value">
                                                        {item.details.endIn}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progress-bar">
                                                <progress max={100} value={70}></progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
            
        } else if (activeTab === 'farming') {
            return (
                <div className="liquidity__table">
                    {liquidityData.map((item, index) => (
                        <div key={index} className="container-liquidity__table-item">
                            <div className="liquidity__table-item">
                                <div className="liquidity__table-item__value">
                                    <div className="liquidity__table-item__pair">
                                        <div className="liquidity__table-item__pair-images">
                                            <div className="one-image">
                                                <img src={ton} alt="" />
                                                <div className="two-image">
                                                    <img src={item.token.image} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="liquidity__table-item__pair-name">
                                            {item.pair}
                                            <div className="absolute"><span>Farm</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">My total liquidity</div>
                                        <div className="liquidity__table-item__tvl-value">{item.myTotalLiquidity}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Farm APR</div>
                                        <div className="liquidity__table-item__tvl-value">{item.farmAPR}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Token</div>
                                        <div className="liquidity__table-item__tvl-value">
                                            <div className="liquidity__table-item__tvl-value-img">
                                                <img src={item.token.image} alt="" />
                                            </div>
                                            <div className="liquidity__table-item__tvl-value-text">{item.token.name}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Rewards in Day</div>
                                        <div className="liquidity__table-item__tvl-value">{item.rewardsInDay}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__tvl">
                                        <div className="liquidity__table-item__tvl-title">Total For claim</div>
                                        <div className="liquidity__table-item__tvl-value green">{item.totalForClaim}</div>
                                    </div>
                                </div>
                                <div className="liquidity__table-item__value-my">
                                    <div className="liquidity__table-item__value--button">Забрать</div>
                                </div>
                            </div>
            
                            

                        </div>
                    ))}
                </div>
            );
            
        }
    };

    return (
        <div className="wrapper">
            <Header />
            <div className="container-p">
                <div className="liquidity">
                    <h1 className="liquidity__title">Добыча ликвидности</h1>
                    <div className="liquidity__description">Получайте доход за счёт предоставления ликвидности</div>
                    <div className="liquidity__actions">
                        <button className="liquidity__button liquidity__button--add" onClick={() => window.location.href='/add-liquidity'}>Добавить ликвидность</button>
                        <button className="liquidity__button liquidity__button--video">
                            <div className="liquidiity__button--video__img">
                            <img src={theme === 'dark' ? videodark : video} alt=""  />
                            </div>
                            <div className="liquidity__button--video__text">Видео</div>
                        </button>
                    </div>
                    <div className="liquidity__tabs">
                        <button
                            className={`liquidity__tab ${activeTab === 'all' ? 'liquidity__tab--active' : ''}`}
                            onClick={() => setActiveTab('all')}
                        >
                            Все пулы
                        </button>
                        <button
                            className={`liquidity__tab ${activeTab === 'farming' ? 'liquidity__tab--active' : ''}`}
                            onClick={() => setActiveTab('farming')}
                        >
                            Фарминг-пулы
                        </button>
                        <button
                            className={`liquidity__tab ${activeTab === 'my' ? 'liquidity__tab--active' : ''}`}
                            onClick={() => setActiveTab('my')}
                        >
                            Моя ликвидность
                        </button>
                    </div>
                    <div className="liquidity__header">
                        <div className="liquidity__select">
                            <div className="tvl"><div className="tvl-text">TVL</div><div className="tvl-img">
                            <img src={theme === 'dark' ? arrows : arrowslight} alt="" /></div></div>
                            <div className="apr">
                                <div className="apr-text">APR</div>
                                <div className="apr-img">
                                <img src={theme === 'dark' ? arrows : arrowslight} alt="" /></div>
                            </div>
                        </div>
                    <div className="liquidity__search">
                        <input type="text" className="liquidity__search-input" placeholder="Найти пул" />
                    </div>
                    <div className="liquidity__select-m">
                    <img src={theme === 'dark' ? filterdark : filterlight} alt="" />
                    </div>
                    </div>
                    {renderTable()}
                    {/* <div className="liquidity__pagination">
                        <button className="liquidity__pagination-button">1</button>
                        <button className="liquidity__pagination-button">2</button>
                        <button className="liquidity__pagination-button">3</button>
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Pools;