import React from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonWallet } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useLocation } from 'react-router-dom';

import './Modal.css';
import Footer from '../../components/Footer/Footer'
import '../../components/Header/Header'

import close from './Images/close.svg';

import walleti from '../../components/Header/Images/wallet.svg';
import copy from '../../components/Header/Images/copy.svg';
import luna from '../../components/Header/Images/luna.svg';
import exit from '../../components/Header/Images/exit.svg';

import tau from './Images/tau.svg';

interface ModalProps {
    isVisible: boolean;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isVisible, onClose }) => {
	const [tonConnectUI, setOptions] = useTonConnectUI();
	const wallet = useTonWallet();
	const userFriendlyAddress = useTonAddress();
	const location = useLocation();
    if (!isVisible) return null;

    const formatAddress = (address: string) => {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    const navigateTo = (path: string) => {
        window.location.href=`${path}`;
    };

    return (
        <div className="modal" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <div className="modal-header-one">
											<div className="title-modal-header">
												Выбор токена
											</div>
										<span className="close" onClick={onClose}>
											<img src={close} alt="" />
										</span>
										</div>
                </div>
                <div className="modal-body">
                    <input type="text" placeholder="Поиск по названию или адресу" />
                    <div className="token-list">
                        <div className="token-list__title">
													Ваши токены
												</div>
												<div className="token-list-table">
													<div className="token-list-table-item">
														<div className="token-table-item__start">
															<div className="token-img">
																<img src={tau} alt="" />
															</div>
															<div className="token-info">
																<div className="token-info__symb">
																	TAU
																</div>
																<div className="token-info__price">
																	$ 0,00607
																</div>
															</div>
														</div>
														<div className="token-table-item__end">
															<div className="token-value">
																120 000
															</div>
															<div className="token-value__price">
																$ 728,3
															</div>
														</div>
													</div>
													<div className="token-list-table-item">
														<div className="token-table-item__start">
															<div className="token-img">
																<img src={tau} alt="" />
															</div>
															<div className="token-info">
																<div className="token-info__symb">
																	TAU
																</div>
																<div className="token-info__price">
																	$ 0,00607
																</div>
															</div>
														</div>
														<div className="token-table-item__end">
															<div className="token-value">
																120 000
															</div>
															<div className="token-value__price">
																$ 728,3
															</div>
														</div>
													</div>
													<div className="token-list-table-item">
														<div className="token-table-item__start">
															<div className="token-img">
																<img src={tau} alt="" />
															</div>
															<div className="token-info">
																<div className="token-info__symb">
																	TAU
																</div>
																<div className="token-info__price">
																	$ 0,00607
																</div>
															</div>
														</div>
														<div className="token-table-item__end">
															<div className="token-value">
																120 000
															</div>
															<div className="token-value__price">
																$ 728,3
															</div>
														</div>
													</div>
													<div className="token-list-table-item">
														<div className="token-table-item__start">
															<div className="token-img">
																<img src={tau} alt="" />
															</div>
															<div className="token-info">
																<div className="token-info__symb">
																	TAU
																</div>
																<div className="token-info__price">
																	$ 0,00607
																</div>
															</div>
														</div>
														<div className="token-table-item__end">
															<div className="token-value">
																120 000
															</div>
															<div className="token-value__price">
																$ 728,3
															</div>
														</div>
													</div>
												</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;